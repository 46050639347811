
import JigTextarea from '@/components/input/JigTextarea.vue'
import JigTextField from '@/components/input/JigTextField.vue'
import IconClose from '@/components/svg/IconClose.vue'
import { PermissionConst, SubscriptionConst, TenantConst, UtilsConst } from '@/constants'
import roleData from '@/data/profile.json'
import Invites from '@/mixin/Invites'
import UserList from '@/mixin/UserList'
import { Permissions } from '@/security/permissions'
import { PermissionActions, StoreConst } from '@/store/constants'
import {
  Tenant,
  TenantSubscription,
  TenantUser,
  TenantUserUpdateRequest,
  UserAttribution,
  UserRoleDefinition,
  UserRoleMap,
} from '@/store/modules/app/types'
import { BulkInviteRequest, CreateMultipleInvitePayload, InviteExt, InviteUserResource } from '@/store/modules/invites/types'
import { DynaconfConfig, Namespace, StandardObject } from '@/store/types'
import JigTextareaWrapper from '@/types/JigTextFieldWrapper'
import { VFormInstance } from '@/types/vuetify'
import { ValidationRules } from '@/utils/input-validation'
import { ListHelpers } from '@/utils/list-helpers'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, State } from 'vuex-class'

declare var consoleError: any

/**
 * The ManageUserModalVue component part of TenantUsers view. It is the major modal to add user(s) or edit a user.
 *
 * The component should cover all relevant UI and functionalities to add/edit users.
 */
@Component({
  components: {
    'jig-textarea': JigTextarea,
    'jig-text-field': JigTextField,
    'icon-close': IconClose,
  },
})
export default class ManageUserModalVue extends Mixins(Invites, UserList) {
  @Prop({ default: false, type: Boolean })
  public isTeamAdminOrSuperUser!: boolean
  @Prop({ default: '', type: String })
  public lastTmNotes!: string
  @Prop({ default: false, type: Boolean })
  public onlyOneAdminUserRemain!: boolean
  @Prop({ default: 0, type: Number })
  public teamAdminCount!: number
  @Prop({ default: 0, type: Number })
  public userLimitCount!: number

  @State('currentSignedInUser', { namespace: Namespace.App })
  public currentSignedInUser!: TenantUser | null
  @State('dynaconfConfig', { namespace: Namespace.App })
  public dynaconfConfig!: DynaconfConfig
  @State('isSuperUser', { namespace: Namespace.App })
  public isSuperUser!: boolean
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('myTenantIndex', { namespace: Namespace.App })
  public myTenantIndex!: number
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]
  @State('tenantUserCount', { namespace: Namespace.App })
  public tenantUserCount!: number
  @State('tenantUserRoles', { namespace: Namespace.App })
  public tenantUserRoles!: UserRoleDefinition[]
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('loadingModal', { namespace: Namespace.Utils })
  public loadingModal!: boolean

  @Getter('tenantUserRolesMap', { namespace: Namespace.App })
  public tenantUserRolesMap!: StandardObject

  @Action('updateTenantUser', { namespace: Namespace.App })
  public updateTenantUser: any
  @Action('createBulk', { namespace: Namespace.Invites })
  public inviteCreateBulk: any

  public $refs!: Vue['$refs'] & {
    userForm: VFormInstance
    emailArea: JigTextareaWrapper
  }

  protected dialogVisible: boolean = false
  private rolesListingModes: any
  private defaultUserRole: UserRoleDefinition = {
    Name: '',
    DisplayName: '',
    RestrictToTenantIds: [],
    disabled: false,
  }
  private companyRoleListMap: UserRoleMap[] = [{ text: 'Select', value: '', type: '' }, ...roleData.roleListMap]
  private emailRules!: any
  private companyRoleRules!: any
  private teamRoleRules: any
  private nameRules!: any
  private submitting: boolean = false
  private submitted: boolean = false
  private isSubmittingUsers: boolean = false
  private emailRows: number = 1
  private emailTextArea!: HTMLTextAreaElement | null
  private updatedAttribution: UserAttribution = { ...TenantConst.defaultUserAttribtes }
  private dialogValid: boolean = false
  private editableUserIsAdmin: boolean = false // If user has Admin permission (tenant:manage)
  private editableUserIsBillingContact: boolean = false
  private selectCompanyRole: UserRoleMap = this.companyRoleListMap[0]
  private userTeamRole: UserRoleDefinition = { ...this.defaultUserRole }
  private subscription: TenantSubscription = SubscriptionConst.defaultSubscription
  private editedUser: TenantUser = TenantConst.defaultTenantUser
  private isEditUserModeOn: boolean = false
  private closedByOutside: boolean = false

  @Watch('dialogVisible')
  private onDialogVisibleChanged(value: boolean) {
    if (this.closedByOutside && !value) {
      this.resetDialogData()

      this.closedByOutside = false
    }
  }

  protected created() {
    this.rolesListingModes = this.rolesListingMode
    this.nameRules = ValidationRules.RequiredName
    this.companyRoleRules = ValidationRules.RequiredCompanyRole
    this.teamRoleRules = ValidationRules.RequiredTeamRole
    this.emailRules = ValidationRules.RequiredBulkEmails
  }

  public get DialogEditUserMode(): boolean {
    return this.isEditUserModeOn
  }

  // `Team Admin` role option is only available to be selected from the dropdown:
  // 1. if user is a TeamAdmin or SuperUser, or
  // 2. when no Team Admin exists in current Tenant, and currenlty signed-in user is a Team Manager
  // Point 2 often happens in existing tenants, where there are only Team Managers. With new release their admin activities might be limited.
  // When this happens we allow currently signed in Team Manager to upgrade self to be Team Admin.
  private get canSelectTeamAdminRole(): boolean {
    return (
      this.isTeamAdminOrSuperUser ||
      (this.isEditUserModeOn && this.editedUser.Uid === this.$auth0.uid && this.isTeamManager && this.teamAdminCount === 0)
    )
  }

  private get dialogSubmitBtnCopy(): string {
    if (this.submitted) {
      return 'Done'
    } else if (this.isEditUserModeOn) {
      return 'Save'
    } else {
      return 'Send invitation'
    }
  }

  private get formTitle(): string {
    if (this.isEditUserModeOn) {
      return 'Edit user information'
    } else {
      return 'Add user'
    }
  }

  private get showDeprecatingMessage(): boolean {
    return this.showTeamManagerCheckbox && !this.disableTeamRoleField && !this.canEditTeamManagerRole && !this.onlyOneAdminUserRemain
  }

  private get isUserLimitReached(): boolean | undefined {
    return (
      this.dynaconfConfig.pricing.sets[this.subscription.PricingSet] != null &&
      this.userLimitCount < this.tenantUserCount + this.editedUser.email.split(/\s*,\s*/).length
    )
  }

  // When Team Admin role is selected, hide Team Manager checkbox.
  // When adding new user, also hide Team Manager checkbox.
  private get showTeamManagerCheckbox(): boolean {
    return this.isEditUserModeOn && !this.isTeamAdminSelected
  }

  private get isTeamAdminSelected(): boolean {
    return (
      this.tenantUserRolesMap[TenantHelpers.roleTeamAdmin1] &&
      this.userTeamRole.Name === this.tenantUserRolesMap[TenantHelpers.roleTeamAdmin1].Name
    )
  }

  // We disable Team role field when:
  // 1. Currently signed-in user cannot edit TeamAdmin permission;
  //    and currently signed-in user is trying to edit an Admin User (TeamAdmin/SuperUser).
  // 2. Or currently signed-in user is an Admin User, trying to edit another Admin User, however there's only 1 Admin User left in the team
  private get disableTeamRoleField(): boolean {
    return this.canSelectTeamAdminRole
      ? this.isEditUserModeOn && this.isTeamAdminSelected && this.onlyOneAdminUserRemain
      : this.isTeamAdminSelected || (this.isEditUserModeOn && this.userIsTeamAdminOrSuperuser(this.editedUser))
  }

  // Since we are deprecating Team Manager, we no longer allow new Team Managers to be created
  // Existing Team Managers can be upgraded to Team Admin or downgraded to Creator/Presenter
  // Once Team Manager checkbox is unchecked, disable it so can not be chekcked back again.
  private get canEditTeamManagerRole(): boolean {
    return this.editableUserIsAdmin && (this.isTeamManager || this.isSuperUser) && (!this.isEditUserModeOn || !this.onlyOneAdminUserRemain)
  }

  private get isTeamManager(): boolean {
    return TenantHelpers.IsTeamManager(this.currentSignedInUser, this.myTenant.ID)
  }

  // User can modify Billing Contact checklist when:
  // 1. Current signed-in user is SuperUser;
  // 2. and target user is not SuperUser;
  // 3. if target user is SuperUser and already is Billing Contact, only downgrade is available.
  private get canEditBillingContact(): boolean {
    return this.isSuperUser && (!this.editedUser.roles.includes(TenantHelpers.roleSuperUser1) || this.editableUserIsBillingContact)
  }

  private get isPresentorSelected(): boolean {
    return (
      this.tenantUserRolesMap[TenantHelpers.rolePresenter1] &&
      this.userTeamRole.Name === this.tenantUserRolesMap[TenantHelpers.rolePresenter1].Name
    )
  }

  public OpenAddUserDialog() {
    this.prepareDialog()
    this.updateDialogEditMode(false)

    let tenantId = TenantHelpers.InvalidTenantID
    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)

    if (tenant != null) {
      tenantId = tenant.ID
    }

    this.resetEditedUser()
    this.editedUser.ID = ListHelpers.calculateNextID(this.tenantUsers, 'ID')
    this.editedUser.status = 'active'
    this.editedUser.tenantId = tenantId

    this.dialogVisible = true
  }

  public UpdateUserTeamRole() {
    if (this.userTeamRole.Name === '' && this.tenantUserRoles.length > 0) {
      this.userTeamRole = { ...this.orderedTenantUserRoles[0] }
    }
  }

  public async OpenUpdateUserDialog(user: TenantUser) {
    this.prepareDialog()
    this.updateDialogEditMode(true)

    this.editedUser = Object.assign({}, user)

    if (this.editedUser.roles.length > 0) {
      const role = TenantHelpers.IsTenantJigSpaceStaff(this.myTenant)
        ? this.editedUser.roles.find((r: string) => r === TenantHelpers.roleSuperUser1) || this.editedUser.roles[0]
        : this.editedUser.roles.find((r: string) => r !== TenantHelpers.roleSuperUser1)
      this.userTeamRole = role == null ? { ...this.defaultUserRole } : this.tenantUserRolesMap[role]
    } else {
      this.userTeamRole = { ...this.defaultUserRole }
    }

    // Now checking ExtraPermissionManager because we need to consider both Team Admin and legacy Team Manager
    // TODO: When Team Manager is completely deprecated, we can simply check `this.editedUser.tenantAdminRoles.indexOf((TenantHelpers.TenantRoleAdmin)` instead
    this.editableUserIsAdmin = this.editedUser.tenantAdminRoles.indexOf(TenantHelpers.ExtraPermissionManager) !== -1
    this.editableUserIsBillingContact = this.editedUser.extraPermissionNames.indexOf(TenantHelpers.ExtraPermissionBillingContact) !== -1

    let existingAttribution: UserAttribution | null = null

    if (
      typeof this.editedUser.user_attributes_json === 'object' &&
      this.editedUser.user_attributes_json != null &&
      !Array.isArray(this.editedUser.user_attributes_json)
    ) {
      // If user_attributes_json returns JSON object, assign directly
      existingAttribution = this.editedUser.user_attributes_json
    } else if (typeof this.editedUser.user_attributes_json === 'string') {
      // If user_attributes_json returns a JSON.stringify'd string value, convert back to JSON object and assign
      try {
        existingAttribution = JSON.parse(this.editedUser.user_attributes_json)
      } catch (error) {
        consoleError('Error parsing user.user_attributes_json JSON', error)
      }
    }

    if (existingAttribution != null && Object.keys(existingAttribution).length > 0) {
      this.updatedAttribution = existingAttribution

      // Unity and Front end platforms have been setting inconsistent values (case difference, string value difference).
      // This is a temporary fix to sync values before we integrate single source of truth method.
      // We don't have to manually set SignupType value if SignupRole is set but it's empty in Dashboard,
      // because the next `selectCompanyRole` value assign method will set it automatically.
      if (this.updatedAttribution.SignupRole != null && this.updatedAttribution.SignupRole !== '') {
        switch (this.updatedAttribution.SignupRole.toLowerCase()) {
          case 'engineer':
            this.updatedAttribution.SignupRole = 'Engineering'
            break
          case 'game developer':
            this.updatedAttribution.SignupRole = 'Game developer'
            break
          case '3d/xr enthusiast':
            this.updatedAttribution.SignupRole = 'XR/3D enthusiast'
            break
          case 'xr/3d enthusiast':
            this.updatedAttribution.SignupRole = 'XR/3D enthusiast'
            break
          case 'undergraduate student':
            this.updatedAttribution.SignupRole = 'Student'
            break
          case 'university faculty member':
            this.updatedAttribution.SignupRole = 'University faculty member'
            break
        }
      }

      this.selectCompanyRole = this.companyRoleListMap.find(
        (rol: UserRoleMap) => rol.value === this.updatedAttribution.SignupRole
      ) as UserRoleMap
    }

    this.dialogVisible = true
  }

  private updateDialogEditMode(isEditMode: boolean) {
    this.isEditUserModeOn = isEditMode
    this.$emit('on-dialog-edit-mode-change', isEditMode)
  }

  private clickedOutside() {
    this.closedByOutside = true
  }

  private resetEditedUser() {
    this.editedUser = { ...TenantConst.defaultTenantUser }
  }

  // Only a Team Admin or SuperUser can delete Team Admins or Team Managers directly
  private userIsTeamAdminOrSuperuser(user: TenantUser): boolean {
    return TenantHelpers.IsTeamAdmin(user) || user.roles.indexOf(TenantHelpers.roleSuperUser1) > UtilsConst.invalidIndex
  }

  private getRoleDefsForUserAndAction(roleMode: number, tenantUser?: TenantUser): UserRoleDefinition[] {
    const cannotDowngradeAdminUser: boolean =
      this.isEditUserModeOn && this.onlyOneAdminUserRemain && TenantHelpers.IsTeamManager(this.editedUser, this.myTenant.ID)

    return this.GetRoleDefsForUserAndAction(roleMode, tenantUser, cannotDowngradeAdminUser, !this.canSelectTeamAdminRole)
  }

  private async confirmDialog() {
    this.isSubmittingUsers = true
    this.$refs.userForm.validate()

    if (!this.dialogValid) {
      this.isSubmittingUsers = false
      return
    }

    if (this.isEditUserModeOn) {
      await this.updateUser()
    } else {
      // Add User
      if (this.isUserLimitReached) {
        this.$emit('on-user-limit-reached')
        segmentEventTracking('TenantUserLimitTeamPanel_FeatureGateShown', {
          tenantName: this.myTenant.Name,
          tenantId: this.editedUser.tenantId,
        })
        this.isSubmittingUsers = false
        return
      }

      await this.addUsers()
    }

    this.isSubmittingUsers = false
  }

  private prepareDialog() {
    // Reset form validation status
    if (this.$refs.userForm) {
      this.$refs.userForm.resetValidation()
    }

    if (this.emailTextArea == null && this.$refs.emailArea != null) {
      this.emailTextArea = this.$refs.emailArea.$el.querySelector('textarea')
    }

    this.UpdateUserTeamRole()
  }

  private onTextareaInput() {
    if (this.emailTextArea == null) {
      this.emailTextArea = this.$refs.emailArea.$el.querySelector('textarea') as HTMLTextAreaElement
    }
    // This is a hack to make sure textarea can shrink down when text lines decrease
    this.emailTextArea.rows = 1

    // padding-top: 11px; padding-bottom: 11px
    // line-height: 16px;
    this.emailRows = Math.min(Math.round((this.emailTextArea.scrollHeight - 22) / 16), 5)
    // Simple reactive binding doesn't reset rows to 1 then back to calculated value, hence setting again manually.
    this.emailTextArea.rows = this.emailRows
  }

  private buildPermissionsQueryParam(isManager: boolean, isBillingContact: boolean): string {
    let result = ''
    if (!isManager && !isBillingContact) {
      return result
    }

    let actionsAddedCount = 0
    result += Permissions.PermTenant.Name + ':'
    if (isManager) {
      result += PermissionActions.Manage
      actionsAddedCount++
    }
    if (isBillingContact) {
      if (actionsAddedCount > 0) {
        result += '|'
      }
      result += PermissionActions.BillingContact
      actionsAddedCount++
    }
    return result
  }

  private async addUsers() {
    const createBulkInviteReq: CreateMultipleInvitePayload = {
      requests: [],
    }
    const emailsToBeInvited = this.editedUser.email.split(/\s*,\s*/)

    const extraPerms = this.buildPermissionsQueryParam(this.editableUserIsAdmin, this.editableUserIsBillingContact)

    const resourcesToAdd: InviteUserResource[] = [
      {
        resourceID: this.myTenant.ID,
        type: PermissionConst.PermissionsResourceType.User,
      },
    ]

    emailsToBeInvited.forEach((email: string) => {
      const userInvite: InviteExt = {
        inviteeEmail: email,
        inviterUserID: this.$auth0.uid,
        tenantID: this.myTenant.ID,
        inviteeRole: this.userTeamRole.Name,
        resources: resourcesToAdd,
        app: 'jigspace',
        inviteeUserAttributes: {
          SignupRole: this.selectCompanyRole.value,
          SignupType: this.selectCompanyRole.type,
        },
      }
      const inviteCreateRequest: BulkInviteRequest = {
        invitation: userInvite,
      }

      createBulkInviteReq.requests.push(inviteCreateRequest)
    })

    await this.inviteCreateBulk(createBulkInviteReq)
    this.extractFailedInvites()
    if (this.failedInvites.length > 0) {
      this.$emit('on-invite-failed', {
        warningPromptMessageHtml: this.craftFailedInvitesCopy(),
      })
    } else {
      this.closeDialog()
    }

    const payload = this.failedInvites.length > 0 ? { loadingMutationName: StoreConst.StoreHelper.loadingModalMutationName } : {}

    // Move tracking after new tenant users load to get the latest user count.
    this.$emit('on-user-invited', {
      getUserDataPayload: payload,
      callback: () => {
        segmentEventTracking('tenantUserCount', {
          user: this.editedUser.email,
          role: this.userTeamRole.Name,
          extraPermissions: extraPerms,
          tenantName: this.myTenant.Name,
          tenantId: this.editedUser.tenantId,
          tenantUserCount: this.tenantUserCount,
        })
      },
    })
  }

  private async updateUser() {
    const payload: TenantUserUpdateRequest = {
      Uid: this.editedUser.Uid,
      TenantId: this.editedUser.tenantId,
      AdditionalPermissions: this.buildPermissionsQueryParam(this.editableUserIsAdmin, this.editableUserIsBillingContact),
      JigRole: this.userTeamRole.Name,
      UserAttributes: this.updatedAttribution,
    }

    this.updateUserCompanyRole()

    const response = await this.updateTenantUser(payload)

    if (!response.isSuccessful || this.apiError !== '') {
      if (response.status === 401 && this.teamAdminCount === 0) {
        this.$emit('on-user-update-failed')
      }
      return
    }

    segmentEventTracking('UpdateUser', {
      jigUserId: this.editedUser.Uid,
      user: this.editedUser.email,
      role: this.userTeamRole.Name,
      extraPermissions: payload.AdditionalPermissions,
    })

    this.closeDialog()
    this.$emit('on-user-updated')
  }

  private closeDialog() {
    this.dialogVisible = false
    this.resetDialogData()
  }

  private resetDialogData() {
    // Reset role select fields
    this.userTeamRole = { ...this.orderedTenantUserRoles[0] }
    this.editableUserIsAdmin = false
    this.editableUserIsBillingContact = false
    // Reset user profile data
    this.updatedAttribution = { ...TenantConst.defaultUserAttribtes }
    this.selectCompanyRole = this.companyRoleListMap[0]

    // Reset form validation status
    this.$refs.userForm.resetValidation()

    this.failedInvites = []
  }

  private updateUserCompanyRole() {
    this.updatedAttribution.SignupRole = this.selectCompanyRole.value
    this.updatedAttribution.SignupType = this.selectCompanyRole.type
  }

  private updateUserExtraPerm() {
    this.editableUserIsAdmin = false

    if (this.isPresentorSelected) {
      this.editableUserIsBillingContact = false
    } else if (this.isTeamAdminSelected) {
      this.editableUserIsAdmin = true
    }
  }
}
