
import JigTextField from '@/components/input/JigTextField.vue'
import LockPromptYesNo from '@/components/modals/LockPromptYesNo.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import ShareModal from '@/components/modals/ShareModal.vue'
import TransferJigPromptYesNo from '@/components/modals/TransferJigPromptYesNo.vue'
import ShareSuccessSnackbar from '@/components/share/ShareSuccessSnackbar.vue'
import { JigConst } from '@/constants'
import JigLock from '@/mixin/JigLock'
import JigPermissions from '@/mixin/JigPermissions'
import MaxJigsCheck from '@/mixin/MaxJigsCheck'
import { Tenant } from '@/store/modules/app/types'
import { JigMetadata } from '@/store/modules/jig/types'
import { Namespace } from '@/store/types'
import { eventBus } from '@/utils/eventBus'
import { segmentEventTracking } from '@/utils/tracking'
import { mixins } from 'vue-class-component'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Mutation, State } from 'vuex-class'

/**
 * The JigActionModalsVue component contains all relevant Jig action modal popups that are used in both JigListing page and JigDetails page.
 * Those action modal popups are:
 * - 1. TransferJigPromptYesNo: to transfer a Jig
 * - 2. DeleteJigPromptYesNo: to delete a Jig
 * - 3. CopyJigPromptYesNo: to duplicate a Jig
 * - 4. ShareModal: Share Jig modal component
 * - 5. JigLimitPromptYesNo: Showing when Jig max limit has reached for tenant/user
 * - 6. JigLockNotifyModal: When MUE lock exists kicks in
 * - 7. ShareSuccessSnackbar: success message when copy deeplink or/and password has successfully happened.
 *
 * Most relevant functionalities have been moved inside this component file,
 * however some particular ones are handled in its parent componet. Rememeer to check each emit function.
 *
 * Some root events dispatched by eventBus from `JigActions` modals are handled here. Please note all eventBus events should be off load in beforeDestroy lifcycle.
 */
@Component({
  components: {
    'jig-text-field': JigTextField,
    'prompt-yes-no': PromptYesNo,
    'prompt-share-modal': ShareModal,
    'lock-prompt-yes-no': LockPromptYesNo,
    'transfer-jig-prompt-yes-no': TransferJigPromptYesNo,
    'share-success-snackbar': ShareSuccessSnackbar,
  },
})
export default class JigActionModalsVue extends mixins(JigLock, JigPermissions, MaxJigsCheck) {
  @Prop({ type: String, default: '' })
  public eventSourceName!: string
  @Prop({ type: String, default: '' })
  public selectedJigIdValue!: string
  @Prop({ type: String, default: '' })
  public selectedJigNameValue!: string

  @State('initializing', { namespace: Namespace.Utils })
  public initializing!: boolean

  @Mutation('updateJigMetadata', { namespace: Namespace.Jig })
  public updateJigMetadata: any

  @Action('deleteJigById', { namespace: Namespace.Jig })
  public deleteJigById: any
  @Action('copyJigById', { namespace: Namespace.Jig })
  public copyJigById: any

  public $refs!: Vue['$refs'] & {
    jigLockNotifyPromptYesNo: LockPromptYesNo
    copyJigPromptYesNo: PromptYesNo
    deleteJigPromptYesNo: PromptYesNo
    jigLimitPromptYesNo: PromptYesNo
    transferJigPrompt: PromptYesNo
    sharePrompt: ShareModal
  }

  private selectedJigId: string = ''
  private selectedJigName: string = ''
  private selectedJigFormatVersion: number = 0
  private newCopyJigName: string = ''
  private selectedJigVisibility: string = ''
  private visibilityPublic: string = JigConst.JigVisibilities.Public
  private clipboardSnackbarVisible: boolean = false
  private latestClipboardEntry: string = ''
  private latestClipboardTitle: string = ''
  private isClipboardCopySuccess: boolean = false

  @Watch('myTenant')
  private onMyTenantChanged(value: Tenant) {
    if (this.initializing) {
      return
    }
  }

  @Watch('selectedJigIdValue')
  private onSelectedJigIdValueChanaged(jigId: string) {
    this.selectedJigId = jigId
    this.updateLockPayloadJigId(jigId)
  }

  @Watch('selectedJigNameValue')
  private onSelectedJigNameValueChanaged(jigName: string) {
    this.selectedJigName = jigName
  }

  protected mounted() {
    this.selectedJigId = this.selectedJigIdValue
    this.selectedJigName = this.selectedJigNameValue
    this.updateLockPayloadJigId(this.selectedJigId)

    eventBus.$on(this.rootJigEvents.showCopyJigPrompt, (payload: { jigId: string; jigName: string; visibility: string }) => {
      this.showCopyJigPrompt(payload.jigId, payload.jigName, payload.visibility)
    })

    eventBus.$on(this.rootJigEvents.showJigPermissionPrompt, (jig: JigMetadata) => {
      this.showJigPermissionsPrompt(jig)
    })

    eventBus.$on(this.rootJigEvents.onJigTransfer, (jig: JigMetadata) => {
      this.transferJigOwnership(jig)
    })

    eventBus.$on(this.rootJigEvents.onJigDelete, (payload: { jigId: string; jigName: string; jigFormatVersion: number }) => {
      this.showDeleteJigPrompt(payload.jigId, payload.jigName, payload.jigFormatVersion)
    })

    eventBus.$on(this.rootJigEvents.onLockNotifyRequired, this.ShowLockNotifyModal)
  }

  // Remove root event listeners when component is unmounting, to avoid memory leak issue.
  protected beforeDestroy() {
    eventBus.$off(this.rootJigEvents.showCopyJigPrompt)
    eventBus.$off(this.rootJigEvents.showJigPermissionPrompt)
    eventBus.$off(this.rootJigEvents.onJigTransfer)
    eventBus.$off(this.rootJigEvents.onJigDelete)
  }

  public ShowInviteNewUsersPrompt(rowsSelectedJigs: any) {
    this.$refs.sharePrompt.ShowPrompt(rowsSelectedJigs)
  }

  public ShowJigPermissionsPromptOnly(jigs: JigMetadata[]) {
    this.$refs.sharePrompt.ShowPrompt(jigs, {
      action: JigConst.jigShareModalMode.manageUser,
    })
  }

  private onUpdateJigShareConfig(payload: any) {
    this.$emit(this.jigEvents.onUpdateJigShareConfig, payload)
  }

  private showShareSuccessMsg(payload: any) {
    this.isClipboardCopySuccess = payload.isClipboardCopySuccess
    this.latestClipboardTitle = payload.latestClipboardTitle
    this.latestClipboardEntry = payload.latestClipboardEntry
    this.clipboardSnackbarVisible = payload.clipboardSnackbarVisible
  }

  private onSharePromptClose(payload: any) {
    this.$emit('on-share-prompt-close', payload)
  }

  private showJigPermissionsPrompt(jig: JigMetadata) {
    const jigId = jig.Id
    const jigName = jig.ProjectName
    this.selectedJigName = jigName

    this.updateLockPayloadJigId(this.selectedJigId)
    this.$emit('on-update-selected-jig-id', jigId)

    this.$refs.sharePrompt.ShowPrompt([jig])
  }

  private async showCopyJigPrompt(jigId: string, jigName: string, visibility: string) {
    const isJigLimitReached = await this.IsMaxJigsLimitReached()

    if (isJigLimitReached) {
      segmentEventTracking('TenantMaxJigLimit_FeatureGateShown', {
        tenantName: this.myTenant.Name,
        tenantId: this.myTenant.ID,
      })
      this.$refs.jigLimitPromptYesNo.ShowPrompt()
    } else {
      this.$emit('on-update-selected-jig-id', jigId)
      this.selectedJigName = jigName
      this.newCopyJigName = `${this.selectedJigName} copy`
      this.selectedJigVisibility = visibility
      this.$refs.copyJigPromptYesNo.ShowPrompt()
    }
  }

  private async copyJig() {
    if (
      await this.copyJigById({
        jigId: this.selectedJigId,
        newName: this.newCopyJigName,
        tenantId: this.myTenant.ID,
      })
    ) {
      segmentEventTracking('JigCopied', {
        ...this.eventTracking(this.eventSourceName),
        jigId: this.selectedJigId,
        jigName: this.newCopyJigName,
        sourceJigName: this.selectedJigName,
      })
      this.$emit('on-jig-copied')
    }
  }

  private showDeleteJigPrompt(jigId: string, jigName: string, jigFormatVersion: number) {
    this.$emit('on-update-selected-jig-id', jigId)
    this.selectedJigName = jigName
    this.selectedJigFormatVersion = jigFormatVersion
    this.$refs.deleteJigPromptYesNo.ShowPrompt()
  }

  private async deleteJig() {
    this.isProcessingPromptYesNo = true
    const isLockObtained = await this.RequestNewLock()
    if (!isLockObtained) {
      this.isProcessingPromptYesNo = false
      return
    }

    if (await this.deleteJigById({ jigId: this.selectedJigId, jigFormatVersion: this.selectedJigFormatVersion })) {
      segmentEventTracking('JigDeleted', {
        ...this.eventTracking(this.eventSourceName),
        jigId: this.selectedJigId,
        jigName: this.selectedJigName,
      })
      this.$refs.deleteJigPromptYesNo.ClosePrompt()

      this.$emit('on-jig-deleted')
    }

    this.isProcessingPromptYesNo = false
  }

  private async transferJigOwnership(jig: JigMetadata) {
    this.updateJigMetadata(jig)
    this.$emit('on-update-selected-jig-id', jig.Id)
    this.updateLockPayloadJigId(jig.Id)
    const isLockObtained = await this.RequestNewLock()

    if (!isLockObtained) {
      return
    }
    this.$refs.transferJigPrompt.ShowPrompt()
  }

  private async onJigOwnershipChanged(payload: any) {
    await this.releaseLock(this.lockPayload)

    this.$emit(this.jigEvents.onJigOwnershipChanged, payload.reloadRequired)
    this.$refs.transferJigPrompt.ClosePrompt()
  }

  private onJigOwnershipChangeFailed() {
    this.releaseLock(this.lockPayload)
  }

  private snackbarClose(payload: boolean) {
    this.clipboardSnackbarVisible = payload
  }
}
