
import { ImageValidationResult } from '@/store/types'
import { ValidationRules } from '@/utils/input-validation'
import { Component, Prop, Vue } from 'vue-property-decorator'

type PreUploadImageValidationCallback = (imageToUpload: File) => string[]
type UploadImageCallback = (imageToUpload: File) => Promise<boolean>
type PostUploadImageCallback = (data: any) => Promise<boolean>

@Component({})

export default class ImageUploaderVue extends Vue {
  @Prop({ default: 512 })
  public minImageWidth!: number
  @Prop({ default: 512 })
  public minImageHeight!: number
  @Prop({ default: 512 })
  public maxImageWidth!: number
  @Prop({ default: 512 })
  public maxImageHeight!: number
  @Prop({ default: 'image/png' })
  public imageAcceptType!: string
  // constrains the image preview to a certain height
  @Prop({ default: 512 })
  public imgPreviewMaxHeight!: number
  // sets the image preview to "contain" the image
  @Prop({ default: false })
  public imgPreviewContain!: boolean

  private imageToUpload!: File
  private uploadRules !: any
  private imageToUploadArrayBuffer: any = ''
  private isShowImageUpload: boolean = false
  private isUploadingImage: boolean = false
  private isImageFileSelected: boolean = false
  private isImageUploadValid: boolean = false
  private uploadExternalValidationErrors: string[] = []
  private filename: any = null

  private callbackPreUploadImageValidation!: PreUploadImageValidationCallback | null
  private callbackUploadImage!: UploadImageCallback
  private callbackPostUploadImage!: PostUploadImageCallback | null

  public get IsShowingDialog(): boolean {
    return this.isShowImageUpload
  }

  private get aspectRatio(): number {
    return this.maxImageWidth / this.maxImageHeight
  }

  protected async created() {
    this.uploadRules = ValidationRules.PngImageUpload
  }

  public Init(uploadRules: any) {
    if (this.isShowImageUpload) {
      // Can't init while using dialog
      return
    }
    if (uploadRules) {
      this.uploadRules = uploadRules
    }
  }

  public Show(
    callbackPreUploadValidation: PreUploadImageValidationCallback | null,
    callbackUploadImage: UploadImageCallback,
    callbackPostUpload: PostUploadImageCallback | null
  ) {
    this.uploadExternalValidationErrors = []
    this.callbackPreUploadImageValidation = callbackPreUploadValidation
    this.callbackUploadImage = callbackUploadImage
    this.callbackPostUploadImage = callbackPostUpload
    if (this.callbackUploadImage === null) {
      return
    }
    this.isShowImageUpload = true
  }

  public Hide() {
    if (!this.isUploadingImage) {
      this.isShowImageUpload = false
    }
  }

  public ClearImageData() {
    this.imageToUploadArrayBuffer = ''
    this.filename = null
  }

  private async uploadImageClick() {
    this.isUploadingImage = true
    if (await this.callbackUploadImage(this.imageToUpload)) {
      if (this.callbackPostUploadImage !== null) {
        await this.callbackPostUploadImage(null)
      }
    }
    this.isUploadingImage = false
    this.isShowImageUpload = false
  }

  private async onImageFileInputChange(file: File) {
    // To display an image preview, we set a v-img src to the FileReader result.
    if (FileReader && file && file.size) {
      const fr = new FileReader()
      fr.onload = () => {
        this.imageToUploadArrayBuffer = fr.result
      }
      fr.readAsDataURL(file)
    } else {
      this.imageToUploadArrayBuffer = ''
    }

    this.uploadExternalValidationErrors = []
    if (file && file.size) {
      this.imageToUpload = file
      this.isImageFileSelected = true
      this.isImageUploadValid = false
      if (this.callbackPreUploadImageValidation !== null) {
        this.uploadExternalValidationErrors = this.callbackPreUploadImageValidation(this.imageToUpload)
        this.isImageUploadValid = (this.uploadExternalValidationErrors.length === 0)
      } else {
        await ValidationRules.ImageSize(
          file, this.minImageWidth, this.minImageHeight,
          this.maxImageWidth, this.maxImageHeight
        ).then(
          (result: ImageValidationResult) => this.onImageValidationComplete(result)
        )
      }
    } else {
      this.isImageFileSelected = false
    }
  }

  private onImageValidationComplete(result: ImageValidationResult) {
    this.isImageUploadValid = result.valid
    if (!result.valid) {
      if (result.xMin === result.xMax && result.yMin === result.yMax) {
        this.uploadExternalValidationErrors = [`Image dimensions must be ${result.xMin} x ${result.yMin} pixels.
          Supplied dimensions: ${result.x} x ${result.y} pixels.`]
      } else {
        this.uploadExternalValidationErrors = [`Image dimensions must conform too:
          ${result.xMin}px >= width <= ${result.xMax}px and ${result.yMin}px >= height <= ${result.yMax}px.
          Supplied dimensions: ${result.x}px x ${result.y}px. `]
      }
    }
  }
}
