
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import IconCheck from '@/components/svg/IconCheckCircleFilled.vue'
import IconInfo from '@/components/svg/IconInfoFilled.vue'
import { MueConst } from '@/constants'
import { Tenant, TenantUser } from '@/store/modules/app/types'
import { JigMetadata } from '@/store/modules/jig/types'
import { Namespace, StandardObject } from '@/store/types'
import { JigLockSharedFunction } from '@/utils/mixin-shared-function'
import { segmentEventTracking } from '@/utils/tracking'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

/**
 * The LockPromptYesNoVue component is part of the MUE Lock System. It is the
 * modal popup that appears when the createLock response status is 423 (lock exists),
 * allowing the user to notify the lock owner.
 *
 * The component displays the name of the current lock owner when available.
 * By passing the `notifyAwaitingLock` action to the `onAnswerYes` prop, an email
 * notification will be triggered upon button click.
 * A relevant tracking event will be fired when the email is sent successfully.
 *
 * The `LockPromptYesNo` component is referenced in the JigLock.ts mixin, so whenever
 * the JigLock mixin is used in a component, this modal popup can be automatically
 * imported and utilized.
 */
@Component({
  components: {
    'prompt-yes-no': PromptYesNo,
    'icon-info': IconInfo,
    'icon-check': IconCheck,
  },
})
export default class LockPromptYesNoVue extends Vue {
  @Prop({ default: undefined, type: Function })
  public onAnswerYes!: (...args: any[]) => Promise<any> | any

  @State('jigMetadata', { namespace: Namespace.Jig })
  public jigMetadata!: JigMetadata
  @State('myTenant', { namespace: Namespace.App })
  public myTenant!: Tenant
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers!: TenantUser[]

  private error: boolean = false
  private submitted: boolean = false
  private isSubmitting: boolean = false
  private sendStatus: string = ''
  private responseMessage: string = ''

  public $refs!: Vue['$refs'] & {
    jigLockNotifyPromptYesNo: PromptYesNo
  }

  private get copy(): StandardObject {
    if (this.error) {
      return {
        title: this.jigLockDoesNotExist ? 'Jig is now editable' : 'Something went wrong',
        yesBtn: this.jigLockDoesNotExist ? 'Close' : 'Try again',
      }
    } else if (this.submitted) {
      return {
        title: 'Email sent',
        yesBtn: 'Close',
      }
    } else {
      return {
        title: 'Someone is editing this Jig',
        yesBtn: 'Notify them',
      }
    }
  }

  private get noBtnClass(): string {
    return this.submitted || this.jigLockDoesNotExist ? 'hidden' : ''
  }

  private get jigName(): string {
    return this.jigMetadata.ProjectName
  }

  private get eventTracking(): any {
    return {
      action: this.$route.name ? this.$route.name.replace(/\s/g, '') : '',
      origin: this.$route.name ? this.$route.name.replace(/\s/g, '') : '',
    }
  }

  private get jigLockOwnerName(): string {
    return JigLockSharedFunction.jigLockOwnerName(this.jigMetadata, this.tenantUsers)
  }

  private get sentSuccessfully(): boolean {
    return this.sendStatus === MueConst.MUENotifyEmailStatus.successful
  }

  private get jigLockDoesNotExist(): boolean {
    return this.sendStatus === MueConst.MUENotifyEmailStatus.invalidJigID
  }

  public ShowPrompt() {
    this.$refs.jigLockNotifyPromptYesNo.ShowPrompt()
    segmentEventTracking('MultiUserEditingLockPanelShown', {
      ...this.eventTracking,
      jigId: this.jigMetadata.Id,
      jigName: this.jigMetadata.ProjectName,
      tenantId: this.myTenant.ID,
      tenantName: this.myTenant.Name,
    })
  }

  public ClosePrompt() {
    this.$refs.jigLockNotifyPromptYesNo.ClosePrompt()
    this.error = false
    this.submitted = false
    this.isSubmitting = false
  }

  /**
   * Usually we use `asyncFunction.constructor.name === 'AsyncFunction'`
   * to check if a function is async or not.
   * However when we pass an async function as a prop to a Vue.js component,
   * it is wrapped internally by Vue.js as a regular function.
   * The async nature of the function is preserved, but the constructor.name
   * property no longer reflects that it's an async function.
   * As a result we check the returned instance is Promise or not as a workaround.
   */

  private async handleOnAnswerYes() {
    let response: any

    if (this.submitted || this.jigLockDoesNotExist) {
      this.ClosePrompt()
      return
    }

    this.isSubmitting = true
    response = this.onAnswerYes()

    if (response instanceof Promise) {
      response = await response
    }

    this.submitted = response.isSuccessful
    this.sendStatus = response.sendStatus
    this.responseMessage = response.message
    this.error = !this.submitted
    this.isSubmitting = false

    if (this.submitted) {
      segmentEventTracking('MultiUserNotificationSent', {
        ...this.eventTracking,
        jigId: this.jigMetadata.Id,
        jigName: this.jigMetadata.ProjectName,
        tenantId: this.myTenant.ID,
        tenantName: this.myTenant.Name,
      })
    }
  }
}
